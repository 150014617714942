import createTypes from 'redux-create-action-types';

export default createTypes(
  'SAFE_CREATE',
  'SAFE_CREATE_ERROR',
  'SAFE_CREATE_SUCCESS',
  'SAFE_DEPLOY',
  'SAFE_DEPLOY_ERROR',
  'SAFE_DEPLOY_FINALIZE',
  'SAFE_DEPLOY_SUCCESS',
  'SAFE_DEPLOY_UNLOCK',
  'SAFE_FUNDED_UPDATE',
  'SAFE_INITIALIZE',
  'SAFE_INITIALIZE_ERROR',
  'SAFE_INITIALIZE_SUCCESS',
  'SAFE_OWNERS',
  'SAFE_OWNERS_ADD',
  'SAFE_OWNERS_ADD_ERROR',
  'SAFE_OWNERS_ADD_SUCCESS',
  'SAFE_OWNERS_ERROR',
  'SAFE_OWNERS_REMOVE',
  'SAFE_OWNERS_REMOVE_ERROR',
  'SAFE_OWNERS_REMOVE_SUCCESS',
  'SAFE_OWNERS_SUCCESS',
  'SAFE_REMOTE_FOUND',
  'SAFE_RESET',
  'SAFE_SWITCH_ACCOUNT',
  'SAFE_UPDATE_NONCE',
  'SAFE_VERSION_UPDATE',
  'SAFE_VERSION_UPDATE_ERROR',
  'SAFE_VERSION_UPDATE_SUCCESS',
);
